@import url('font-awesome-6.5.1.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
body {
  padding: 0px 0px;
  margin: 0px;
  background: #f1f5f9 !important;
  font-family: var(--font-family);
  font-family: "Inter", sans-serif;
  color: var(--text-color);
  transition: all .5s ease 0s;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: var(--gray-500);
}
.clear {
  font-size: 0px;
  line-height: 0px;
  clear: both;
}
a:focus, a:hover {
  text-decoration: none;
}
li:focus {
  outline: none;
}
a:focus {
  outline: none;
}
a {
  text-decoration: none;
}
#topcontrol {
  background: url(../images/go-top.gif) no-repeat;
  width: 48px;
  height: 48px;
  z-index: 99999;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  /* IE 6-9 */
  transition: all .5s ease 0s;
  margin: 0 0 20px 0;
}
#topcontrol:hover {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  transition: all .5s ease 0s;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
button {
  border-radius: revert-layer;
}
* {
  scrollbar-width: thin;
  /*   scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);*/
  scrollbar-color: #bdd2d9 #f1f1f1;
}
/***************************************************************/
.loginsection_bg {
  margin: 0 auto;
  /* min-height:660px; */
  height: calc(100vh - 0px);
  padding: 0 0;
  width: 100%;
  background-attachment: fixed;
  background-image: url("../images/login-bg.jpg");
  background-position: center center;
  background-size: cover;
  position: relative;
  background-color: #171b1b;
  position: relative;
}
.loginbox {
  padding: 15px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
  background: rgba(255, 255, 255, 0.7);
  margin: 0% auto;
  max-width: 380px;
  max-height: 350px;
  position: absolute;
  right: 15px;
  bottom: 15px;
  border-radius: 15px;
}
.loginboxLogo {
  padding: 10px 0 20px 0;
  margin: 0;
  width: 130px;
}
.loginboxLogo img {
  padding: 0;
  margin: 0 auto;
  width: 100%;
}
.card {
  padding: 1rem;
  margin-bottom: 1rem;
}
.content-header {
  position: relative;
  padding: 0 0 10px 0;
}
.content-header:after, .content-header:before {
  clear: both;
  padding: 0;
  margin: 0;
  display: block;
  content: "";
}
.content-header h4 {
  padding: 9px 0;
  margin: 0;
}
.card_box {
  padding: 0;
  margin: 0 auto 10px;
}
.box_section {
  padding: 10px;
  display: flow-root;
  cursor: pointer;
}
.box_section:hover{
  -webkit-box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 35%);;
  -moz-box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 35%);;
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 35%);
}
.box_section h4 {
  padding: 0;
  margin: 0;
  font-weight: 600;
}
.box_section p {
  padding: 0;
  margin: 0;
  font-size: 15px;
  color: #868686;
  transition: all .5s ease 0s;
}
.card_box .icon_box {
  padding: 0;
  margin: 0 10px 0 0;
  font-size: 35px;
  width: 50px;
  text-align: center;
  border-radius: var(--bs-card-border-radius);
  color: #fff;
}
.box-header {
  display: block;
  padding: 10px;
  margin: 0;
  position: relative;
}
.box-header:after, .box-header:before {
  clear: both;
  padding: 0;
  margin: 0;
  display: block;
  content: "";
}
.box-header h5 {
  padding: 0 0 10px 0;
  margin: 0;
  color: #315e96;
  font-weight: 600;
}
.box-header::after {
  content: "";
  height: 1px;
  width: 100%;
  background: linear-gradient(to right, #565af0, #005dab00);
  display: block;
}
.box-header .pull-right button {
  margin: -15px 0 0 0;
  /* display: none; */
}
.p-menu ul {
  margin: 0 !important;
  padding: 0 !important;
}
.box-body {
  display: block;
  padding: 10px;
  margin: 0;
  position: relative;
}
.box-footer {
  border-top: 1px solid #f4f4f4;
  display: block;
  padding: 10px;
  margin: 0;
  position: relative;
}
.align-center {
  text-align: center;
}
.field {
  margin-bottom: 20px;
}
.field > label {
  color: var(--text-color);
  font-size: 12px;
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}
.layout-static-inactive .field > label {
  font-size: 14px;
}
.p-inputtext, .p-multiselect, .p-dropdown, .p-calendar {
  width: 100%;
}
.field ul, .p-dropdown-items-wrapper ul {
  padding: 0;
  margin: 0;
}
.Form_box {
  margin-top: 25px;
}
.back-button {
  position: absolute;
  left: 0;
  top: 5px;
  background-color: #dedfe4;
  color: #325e04;
  border: none;
  font-size: 20px;
}
.back-button:hover {
  background: #aeadeb;
  color: #325e04;
  border: none;
}
.text-red {
  color: #dd4b39 !important;
}
.e-button {
  font-weight: 400 !important;
  font-size: 1rem !important;
  font-family: inherit !important;
  line-height: inherit !important;
  background: var(--surface-100) !important;
  color: var(--bluegray-900) !important;
  border-color: var(--surface-300) !important;
}
.e-button:hover {
  background: var(--surface-200) !important;
  color: var(--bluegray-900) !important;
}
.m-button {
  color: #ffffff;
  background: #565af0;
  border: 1px solid #565af0;
  padding: 0.75rem 1.25rem !important;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  outline-color: transparent;
}
.m-button:hover {
  background: #2563eb;
  color: #ffffff;
  border-color: #2563eb;
}
.m-button-two {
  color: #000000;
  background: #f6f6fb;
  border: 1px solid #e24c4c;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  outline-color: transparent;
}
.m-button-two:hover {
  background: #e24c4c;
  color: #ffffff;
  border-color: #e24c4c;
}
.s-button {
  color: #363a51;
  background: transparent;
  border: 1px solid #e2e8f0;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  outline-color: transparent;
}
.s-button:hover {
  background: #e2e8f0;
  color: #363a51;
  border-color: #e2e8f0;
}
.o-button {
  color: #ffffff;
  background: #a1a3f7;
  border: 1px solid #a1a3f7;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  outline-color: transparent;
}
.o-button:hover {
  background: #8b8dee;
  color: #ffffff;
  border-color: #e2e8f0;
}
.d-button {
  color: #475569;
  background: #f1f5f9;
  border: 1px solid #d6dbe2;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  outline-color: transparent;
}
.d-button:hover {
  background: #e2e8f0;
  color: #334155;
  border-color: #dae2eb;
}
.x-button {
  background: #ffffff;
  border-radius: 6px;
  display: inline-block;
}

.xx-button {
	display: inline-block;
}
.xx-button .p-button {
  color: #363a51;
  background: transparent;
  border: 1px solid #e2e8f0;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  outline-color: transparent;
}

.xx-button .p-button:hover {
  background: #e2e8f0;
  color: #363a51;
  border-color: #e2e8f0;
}



.xx-button .p-button .p-button-label {
	font-weight: 400;
}

.p-fileupload .p-button {
  background: transparent;
  color: #64748b;
}
.p-fileupload .p-button:hover {
  background: #cbd5e1;
  color: #1e293b;
}
.card_box_d {
  position: relative;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  min-height: 150px;
  margin-bottom: 15px;
}
.card_box2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 55px;
}
.card_box2 p {
  text-align: center;
  line-height: 40px;
  font-size: 15px;
}
.layout-static-inactive .card_box2 p {
  font-size: 14px;
  line-height: 22px;
  padding: 7px 5px;
}
.Dashboard-AnalyticsSection {
  min-height: 350px;
}
.card_box3 {
  position: relative;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #d2d2d2;
  border-right: 5px solid #14b8a6;
  border-radius: 8px;
  padding: 3px;
  margin: 0 auto 7px;
}
.card_box3 h5 {
  padding: 0;
  margin: 0;
  text-align: center;
  color: #0f9485;
}
.card_box3 p {
  padding: 0;
  margin: 0;
  font-size: 11px;
  text-align: center;
}
ul.States_box {
  padding: 0;
  margin: 0;
}
ul.States_box li {
  padding: 0.75rem 1.25rem;
  margin: 2px;
  list-style: none;
  border: 1px solid #d1d5db;
  display: inline-block;
  border-radius: 6px;
  font-size: 1rem;
  color: #4b5563;
}
.Granularitybox {
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 15px;
  margin-top: 10px !important;
  font-size: 13px;
}
.layout-static-inactive .Granularitybox {
  font-size: 13px;
}
.Granularitybox2 {
  margin-bottom: 0;
}
.six_box_section h2 {
  text-align: center;
  font-weight: bold;
}
.six_box_section h6 {
  text-align: right;
  padding: 10px 10px 0 0;
  font-size: 22px;
}
.p-datatable-table .p-button {
  padding: 0rem 1.25rem;
}
.p-datatable-table .p-link.p-button {
  padding: 0rem;
}
.box-body .p-datatable .p-datatable-thead > tr > th, .box-body .p-datatable .p-datatable-tbody > tr > td, .box-body .p-datatable .p-datatable-tfoot > tr > th {
  padding: 7px;
  font-size: 13px;
  /* word-wrap: anywhere; */
}
.fileUploadsection {
  padding: 0;
  margin: 0;
}
.fileUploadsection .p-fileupload .p-fileupload-buttonbar {
  background: transparent;
  border: none;
  padding: 0;
}
.fileUploadsection .p-button {
  color: #43515f;
  background: #ecf1f8;
  border: 2px dotted #808080;
  padding: 0.75rem 1.25rem;
  margin: 0 auto;
  display: inherit;
}
.fileUploadsection .p-fileupload-content {
  padding: 0;
  margin: 0 auto;
  border: none;
}
.fileUploadsection .p-fileupload-content .p-fileupload-row {
  padding: 0;
  margin: 0 auto;
  border-bottom: 2px dotted #98999a;
}
.fileUploadsection .p-fileupload-content .p-button {
  color: #000000;
  background: transparent;
  padding: 0;
  margin: 0 auto;
  display: flex;
  border: none;
  font-weight: bold;
}
.menu-section {
  position: relative;
  margin-top: 0;
  margin-bottom: 15px;
}
.section-field {
  font-size: 14px;
  color: var(--gray-700);
  font-weight: 600;
}
.section-value {
  margin-top: -8px;
  color: var(--text-color);
}
.badge2 {
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 10px;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  border: 1px solid;
  color: var(--bluegray-900);
  width: auto;
}
.btn-Approved {
  background: #4fc56d;
  border-color: #42b25e;
  color: #ffffff;
}
.engagement-section {
  padding: 0;
  margin: 0;
}
.engagement-section h5 {
  padding: 0;
  margin: 0 auto 20px;
  color: #12c2fb;
  font-weight: 600;
}
.engagement-section h5 span {
  color: #0b7b9f;
}
.engagement-section h6 {
  color: #01131a;
  font-weight: 800;
}
.collapsbutton {
  position: relative;
}
.collapsbutton .btn::after {
  content: "\f077";
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
  text-align: center;
  padding: 0 0;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
}
.collapsbutton .btn.collapsed::after {
  content: "\f078";
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
  text-align: center;
  padding: 0 0;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
}
.inline-block {
  display: inline-block;
}
ul.Attachments_box {
  padding: 0;
  margin: 0;
}
ul.Attachments_box > li {
  padding: 5px 0;
  margin: 0;
  list-style: none;
  font-size: 15px;
  border-bottom: 1px dotted #98999a;
  word-break: break-all;
}
ul.Attachments_box > li > span {
  padding: 0;
  margin: 0 5px 0 0;
  font-size: 20px;
}
ul.Attachments_box > li > button.xmark_box {
  padding: 10px 5px !important;
  margin: 0 10px 0 30px !important;
  float: right !important;
  color: #ff1e00 !important;
  border-radius: 0px !important;
}
ul.Attachments_box > li button {
  border: none;
  padding: 0;
}
.tablesection {
  padding: 0;
  margin: 0;
  width: 100%;
}
.table tr th {
  background: #ecf1f8;
  color: #43515f;
  font-weight: 500;
}
.table tr th, .table tr td {
  white-space: collapse;
  padding: 7px;
  font-size: 13px;
  vertical-align: middle;
}
.audit-table .table tr th, .audit-table .table tr td {
  min-width: 10rem;
}
.p-dialog-header {
  border-bottom: 1px solid;
  border-color: var(--gray-300);
  padding: 1rem;
}
.p-dialog-content {
  padding: 1rem;
}
@media only screen and (min-width:200px) and (max-width:767px) {
  .div-center {
    text-align: center !important;
  }
}
.custom-content {
  padding: 0;
  margin-top: 10px auto;
}
.custom-content .ul .li {
  padding: 0;
  margin-top: 0 auto;
  border-bottom: 2px dotted #98999a;
}
.fileUploadsection .p-fileupload-content .p-fileupload-row {
  padding: 0;
  margin: 0 auto;
  border-bottom: 2px dotted #98999a;
  visibility: hidden;
  position: absolute;
}
.p-tag.p-tag-success {
  background-color: var(--green-100);
  color: var(--green-800);
}
.p-tag.p-tag-warning {
  background-color: var(--yellow-100);
  color: var(--yellow-800);
}
.p-tag.p-tag-info {
  background-color: var(--blue-100);
  color: var(--blue-800);
}
.card .p-tabview .p-tabview-nav-content ul {
  padding: 0;
  margin: 0;
}
.card .p-tabview .p-tabview-nav-content ul li a {
  color: #6b7280;
}
.card .p-tabview .p-tabview-nav-content ul li.p-highlight a {
  color: #3B82F6;
}
.boxsection ul {
  padding: 0;
  margin: 0;
}
.boxsection .p-stepper-panels {
  padding: 0;
}
.boxsection .p-tabview-panels {
  padding: 0;
}
.Audit_box h5 {
  padding: 0;
  margin: 0 auto 20px;
  color: #12c2fb;
  font-weight: 600;
}
.Audit_box .p-accordion-content {
  padding: 0;
  margin: 0;
}
.Audit_box .table tr th, .Audit_box .table tr td {
  /* word-break: break-all; */
  vertical-align: top;
}
.field.BidBox .p-element {
  width: 100%;
}
.field.BidBox .p-element .p-component {
  width: 100%;
}
.customer-badge, .product-badge, .order-badge {
  border-radius: var(--border-radius);
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}
.customer-badge.status-proposal {
  background: #FFD8B2;
  color: #805b36;
}
.customer-badge.status-renewal {
  background: #ECCFFF;
  color: #694382;
}
.customer-badge.status-new {
  background: #B3E5FC;
  color: #23547b;
}
.customer-badge.status-qualified {
  background: #C8E6C9;
  color: #256029;
}
.filterbutton .p-element {
  padding: 3px 15px;
  margin: 0;
  display: inline-block;
}
.filterbutton .p-element .p-button-label {
  font-weight: 400;
}
.filterbutton .p-element:focus {
  box-shadow: none;
}
.value_tag .p-tag-value {
  font-size: 14px;
}
.p-paginator .p-dropdown-items-wrapper ul {
  padding: 0;
  margin: 0;
}
.Granularitysection .p-button-label {
  font-weight: 400;
}
.Yearsection .p-button-label {
  font-weight: 400;
}
.Quarter-Wisesection .p-button-label {
  font-weight: 400;
}
.Granularitysection, .Yearsection, .Monthsection, .Quarter-Wisesection {
  margin: 5px 10px;
}
.p-button:focus {
  box-shadow: none;
}
.layout-menu ul a:focus {
  box-shadow: none;
}
.field.inputNumber-box .p-element {
  display: grid;
}
.Access-Control-box {
  padding: 5px 0 25px 0;
  margin: 0 auto;
  border-bottom: 1px solid #eaeaea;
}
.Access-Control-box:last-child {
  border-bottom: 1px solid transparent;
}
.Access-Control-box .pull-left {
  padding: 0;
  margin: 0;
  width: 40%;
}
.Access-Control-box .pull-right {
  padding: 0px;
  margin: 0px;
  width: 50%;
  text-align: center;
}
.Access-Control-box h4 {
  padding: 0;
  margin: 0 0 10px 0;
  font-size: 19px;
}
.Access-Control-box .Access-Control-box2 {
  padding: 0;
  margin: 0 0 0 0;
}
.Access-Control-box .Access-Control-box2 .ng-star-inserted {
  padding: 0;
  margin: 0 15px 10px 0;
  display: inline-block;
}
.Access-Control-box .Access-Control-box2 .ng-star-inserted p-checkbox {
  padding: 0;
  margin: 0 5px 0 0;
}
.p-checkbox-box {
  position: relative;
}
.p-checkbox-box .p-icon-wrapper .p-icon {
  position: absolute;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
}
.layout-topbar .layout-topbar-logo img {
  height: 60px;
  margin-right: 0.5rem;
}
.layout-topbar .layout-topbar-menu .layout-topbar-button.ProfileButton {
  margin-left: 0;
  display: flex;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  border-radius: 12px;
  padding: 1rem;
}
.layout-topbar .layout-topbar-menu .layout-topbar-button.ProfileButton i {
  margin-right: 0.5rem;
}
/* ---------------------------------------------------------------------------------------------- */
.layout-sidebar {
  width: auto;
  /* width: 250px;   */
  /* height: auto; */
  /* left: 1rem; */
  box-shadow: none;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  transform: translate(-100%);
  left: 0;
}
/* .layout-sidebar {
	position: absolute;
	z-index: 9;
} */
@media (min-width: 992px) {
  .layout-wrapper.layout-static .layout-main-container {
    margin-left: 0;
    padding-left: 1rem;
  }
  .layout-wrapper.layout-static.layout-static-inactive .layout-main-container {
    /* margin-left: 250px; */
    margin-left: 245px;
  }
  .layout-wrapper.layout-static.layout-static-inactive .layout-sidebar {
    transform: translate(0%);
    left: 1rem;
    ;
  }
}
.layout-main-container {
  padding-right: 1rem;
  padding-top: 6rem;
}
/* ---------------------------------------------------------------------------------------------- */
.Win-Loss-box div {
  margin-left: 0 !important;
}
.Win-Loss-box ul {
  padding: 55% 0 0 0;
  margin: 0;
}
.Win-Loss-box ul li {
  padding: 2px 10px 2px 2px;
  margin: 0;
  list-style: none;
  color: #666666;
  font-size: 12px;
  display: inline-block;
}
.Proposalsfield {
  position: absolute;
  right: 0;
  top: -5px;
}
.marquee_box {
  padding: 0;
  margin: 0;
}
.marquee_box ul {
  padding: 0;
  margin: 0;
}
.marquee_box ul li {
  padding: 5px 5px;
  margin: 0;
  list-style: none;
  display: inline-block;
  color: #002b72;
}
.marquee_box ul li span {
  padding: 5px 8px;
  margin: 0 5px;
  background: #999;
  border-radius: 9px;
}
.marquee_box ul li span.bg1 {
  background: #add688;
  color: #ffffff;
}
.marquee_box ul li span.bg2 {
  background: #ffdc7b;
  color: #654a00;
}
.marquee_box ul li span.bg3 {
  background: #e7cdff;
  color: #a855f7;
}
.marquee_box ul li span.bg4 {
  background: #cfe2ff;
  color: #0c6efd;
}
.marquee_box ul li font {
  color: #7a7a7a;
}
.marquee_box ul li strong {
  color: #0c6efd;
}
.layout-topbar .layout-topbar-logo {
  width: 170px;
}
.width-100 {
  width: 100%;
  display: inherit;
  padding-left: 0;
  padding-right: 0;
  margin: 0px !important;
}
/* Tooltip1 */
font.Tooltip1 {
  position: absolute;
  overflow: hidden;
  right: 5px;
  color: #1400ff;
}
font.Tooltip1 span {
  position: absolute;
  color: #ffffff;
  background: #4b5563;
  width: 280px;
  font-size: 12px;
  line-height: 20px;
  padding: 8px;
  transition: 0.3s;
  opacity: 0;
  text-align: left;
  /* padding: 5px; */
}
font.Tooltip1:hover {
  overflow: visible;
}
font.Tooltip1:hover span {
  opacity: 1;
}
font.Tooltip1::after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
}
font.Tooltip1[data-position="top"] span {
  top: -80px;
  right: -213px;
  z-index: 99;
}
font.Tooltip1[data-position="top"] span::after {
  border-top-color: black;
  left: 50px;
  bottom: -20px;
}
font.Tooltip1[data-position="left"] span {
  top: 10px;
  left: -150px;
}
font.Tooltip1[data-position="left"] span::after {
  border-left-color: black;
  right: -20px;
  bottom: 5px;
}
font.Tooltip1[data-position="bottom"] span {
  bottom: -45px;
  left: -10px;
}
font.Tooltip1[data-position="bottom"] span::after {
  border-bottom-color: black;
  left: 50px;
  top: -20px;
}
font.Tooltip1[data-position="right"] span {
  top: 10px;
  right: -150px;
}
font.Tooltip1[data-position="right"] span::after {
  border-right-color: black;
  left: -20px;
  bottom: 5px;
}
font.Tooltip1[data-position="top"] span::after {
  border-top-color: #4b5563;
  left: 50px;
  bottom: -20px;
}
font.Tooltip1 span::after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
}
/* Tooltip1 */
.Attachments-section {
  padding: 0;
  margin: 0;
}
.Attachments-section ul {
  padding: 0;
  margin: 0;
}
.Attachments-section ul li {
  padding: 0 15px 15px 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}
.livetile {
  cursor: pointer;
}
.Attachments1 {
  display: inline-block;
  margin: 0 15px 15px 0;
}
.Attachments1 a {
  color: #0d6efd !important;
}
.Attachments1 a:hover {
  color: #0a58ca !important;
}
.p-datepicker table th {
  text-align: center !important;
}
.section_box {
  flex: 0 0 auto;
  width: 20%;
}
.card_box:after, .card_box:before {
  clear: both;
  padding: 0;
  margin: 0;
  display: block;
  content: "";
}
.proposalssection .box_section p {
  font-size: 13px;
}
.layout-static-inactive .proposalssection .box_section p {
  font-size: 9px;
}
.proposalssection.card_box .icon_box {
  font-size: 20px;
  width: 30px;
}
.ProposalList-boby .p-tag.p-tag-info {
  background: #caf1d8;
  color: #136c34;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  font-weight: 500;
}

.link_alt {
  color: var(--surface-600);
  &:hover {
  color: var(--blue-800);
  }
}


.link_alt {
  color: var(--surface-600);
  &:hover {
  color: var(--blue-800);
  }
}

.gray_background {
  background-color: var(--blue-50);
}


.notification_item {
  border-radius: 6px;

  p {
    margin: 0;
    padding: 0;
  }
  &:hover {
    cursor: pointer;
    background-color: var(--surface-50);
  }

  &:not(:first-child) {
    margin-top: 1rem;
  }

}
.unread_notification {
  /* background-color: var(--blue-50); */
  color: var(--surface-700);
  border-radius: 6px;
  &:hover {

  /* background-color: var(--blue-50); */
  color: var(--surface-800);

  }
&::after {
  display: inline;
    content: '•';
    font-size: 2rem;
    color: var(--blue-500);
  }
}
.read_notification {
  color: var(--surface-400);
  border-radius: 6px;
  &:hover {

  /* background-color: var(--blue-50); */
  color: var(--surface-600);

  }
}

.notification_duration {
  color: var(--surface-400);
  font-weight: 500;
  font-size: 12px;
}
.notification-bell {
  padding: 10px;
  &:hover{
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--blue-50);
  }
}

.notification-toggle-btn {
.p-button-group .p-button:last-of-type:not(:only-of-type), .p-button-group p-button:last-of-type:not(:only-of-type) .p-button, .p-buttonset .p-button:last-of-type:not(:only-of-type), .p-buttonset p-button:last-of-type:not(:only-of-type) .p-button {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.p-button-group .p-button:first-of-type:not(:only-of-type), .p-button-group p-button:first-of-type:not(:only-of-type) .p-button, .p-buttonset .p-button:first-of-type:not(:only-of-type), .p-buttonset p-button:first-of-type:not(:only-of-type) .p-button {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.p-selectbutton .p-button {
    border: transparent;

}
.p-selectbutton .p-button.p-highlight {
    background: var(--blue-50);
    border-color: #3B82F6;
    color: var(--blue-700);
}
}
.p-badge {
    background: var(--orange-500);
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
}
.p-overlay-badge .p-badge {
    position: absolute;
    top: 12px;
    right: 15px;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
    margin: 0;
}
.p-overlaypanel {
    position: absolute;
    margin-top: 10px;
    max-height: 840px;
    overflow: auto;
    /* left: 0; */
}

.action-btn-tertiary {

.p-button {
    color: var(--surface-700);
    font-weight: 400;
    background: #fff;
    border: 1px solid var(--surface-300);
    padding: 0.45rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s,
      color 0.2s,
      border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 6px;
    outline-color: transparent;
}
.p-button-label {
    font-weight: 500;
}
}

.ui-sortable-column-icon.pi.pi-fw.pi-sort {
  visibility: hidden;
}
