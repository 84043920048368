@media only screen and (min-width:200px) and (max-width:767px) {


  .Granularitysection,.Yearsection,.Monthsection, .Quarter-Wisesection{
    width: 100%;
  }
  .Granularitysection .pull-left,.Yearsection .pull-left,.Monthsection .pull-left, .Quarter-Wisesection .pull-left{
    width: 100%;
  }

  .Granularitybox {
    margin-bottom: 5px;
  }
  .Granularitysection .p-button-label {
    font-size: 12px;
  }
  .Win-Loss-box ul{
    padding:0% 0 0 0;
    margin: 0;
  }
  .section_box {
    width: 100%;
  }

.box-header .pull-left{
  margin-bottom: 15px;
}

.layout-footer p{
  text-align: center;
}

.back-button {
	left: 4px;
	top: -4px;
}

}
@media only screen and (min-width:200px) and (max-width:767px) and (orientation:portrait) {}
@media only screen and (min-width:200px) and (max-width:767px) and (orientation:landscape) {

  
}
/*************************/
@media only screen and (min-width:768px) and (max-width:1024px) {
    .Granularitybox {
        display: block;
    }
  .Granularitybox3{
    margin-top: 15px;
  }
  .section_box {
    width: 50%;
  }

}
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation:portrait) {

  
}
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation:landscape) {

  
}
/*************************/
@media only screen and (min-width:810px) and (max-width:1080px) {

  
}
@media only screen and (min-width:810px) and (max-width:1080px) and (orientation:portrait) {

  
}
@media only screen and (min-width:810px) and (max-width:1080px) and (orientation:landscape) {

  .Dashboard-AnalyticsSection{
    min-height: 380px;
  }
}

@media only screen and (min-width:820px) and (max-width:1180px) {

  
}
@media only screen and (min-width:820px) and (max-width:1180px) and (orientation:portrait) {

  
}
@media only screen and (min-width:820px) and (max-width:1180px) and (orientation:landscape) {

  .Dashboard-AnalyticsSection{
    min-height: 380px;
  }
}






/*************************/
@media only screen and (min-width:992px) and (max-width:1023px) {}
/*************************/
@media only screen and (min-width:1024px) and (max-width:1200px) {
}
/*************************/
@media only screen and (min-width:1201px) {}