/* You can add global styles to this file, and also import other style files */
@import "assets/layout/styles/layout/layout.scss";

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";




:root, [data-bs-theme="light"] {
	--bs-blue: #0d6efd;
	--bs-indigo: #6610f2;
	--bs-purple: #6f42c1;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #fd7e14;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-black: #000;
	--bs-white: #fff;
	--bs-gray: #6c757d;
	--bs-gray-dark: #343a40;
	--bs-gray-100: #f8f9fa;
	--bs-gray-200: #e9ecef;
	--bs-gray-300: #dee2e6;
	--bs-gray-400: #ced4da;
	--bs-gray-500: #adb5bd;
	--bs-gray-600: #6c757d;
	--bs-gray-700: #495057;
	--bs-gray-800: #343a40;
	--bs-gray-900: #212529;
	--bs-primary: #0d6efd;
	--bs-secondary: #6c757d;
	--bs-success: #198754;
	--bs-info: #0dcaf0;
	--bs-warning: #ffc107;
	--bs-danger: #dc3545;
	--bs-light: #f8f9fa;
	--bs-dark: #212529;
	--bs-primary-rgb: 13,110,253;
	--bs-secondary-rgb: 108,117,125;
	--bs-success-rgb: 25,135,84;
	--bs-info-rgb: 13,202,240;
	--bs-warning-rgb: 255,193,7;
	--bs-danger-rgb: 220,53,69;
	--bs-light-rgb: 248,249,250;
	--bs-dark-rgb: 33,37,41;
	--bs-primary-text-emphasis: #052c65;
	--bs-secondary-text-emphasis: #2b2f32;
	--bs-success-text-emphasis: #0a3622;
	--bs-info-text-emphasis: #055160;
	--bs-warning-text-emphasis: #664d03;
	--bs-danger-text-emphasis: #58151c;
	--bs-light-text-emphasis: #495057;
	--bs-dark-text-emphasis: #495057;
	--bs-primary-bg-subtle: #cfe2ff;
	--bs-secondary-bg-subtle: #e2e3e5;
	--bs-success-bg-subtle: #d1e7dd;
	--bs-info-bg-subtle: #cff4fc;
	--bs-warning-bg-subtle: #fff3cd;
	--bs-danger-bg-subtle: #f8d7da;
	--bs-light-bg-subtle: #fcfcfd;
	--bs-dark-bg-subtle: #ced4da;
	--bs-primary-border-subtle: #9ec5fe;
	--bs-secondary-border-subtle: #c4c8cb;
	--bs-success-border-subtle: #a3cfbb;
	--bs-info-border-subtle: #9eeaf9;
	--bs-warning-border-subtle: #ffe69c;
	--bs-danger-border-subtle: #f1aeb5;
	--bs-light-border-subtle: #e9ecef;
	--bs-dark-border-subtle: #adb5bd;
	--bs-white-rgb: 255,255,255;
	--bs-black-rgb: 0,0,0;
	--bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	--bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
	--bs-gradient: linear-gradient( 180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
	--bs-body-font-family: var(--bs-font-sans-serif);
	--bs-body-font-size: 1rem;
	--bs-body-font-weight: 400;
	--bs-body-line-height: 1.5;
	--bs-body-color: #212529;
	--bs-body-color-rgb: 33,37,41;
	--bs-body-bg: #fff;
	--bs-body-bg-rgb: 255,255,255;
	--bs-emphasis-color: #000;
	--bs-emphasis-color-rgb: 0,0,0;
	--bs-secondary-color: rgba(33, 37, 41, 0.75);
	--bs-secondary-color-rgb: 33,37,41;
	--bs-secondary-bg: #e9ecef;
	--bs-secondary-bg-rgb: 233,236,239;
	--bs-tertiary-color: rgba(33, 37, 41, 0.5);
	--bs-tertiary-color-rgb: 33,37,41;
	--bs-tertiary-bg: #f8f9fa;
	--bs-tertiary-bg-rgb: 248,249,250;
	--bs-heading-color: inherit;
	--bs-link-color: #0d6efd;
	--bs-link-color-rgb: 13,110,253;
	--bs-link-decoration: underline;
	--bs-link-hover-color: #0a58ca;
	--bs-link-hover-color-rgb: 10,88,202;
	--bs-code-color: #d63384;
	--bs-highlight-color: #212529;
	--bs-highlight-bg: #fff3cd;
	--bs-border-width: 1px;
	--bs-border-style: solid;
	--bs-border-color: #dee2e6;
	--bs-border-color-translucent: rgba(0, 0, 0, 0.175);
	--bs-border-radius: 0.375rem;
	--bs-border-radius-sm: 0.25rem;
	--bs-border-radius-lg: 0.5rem;
	--bs-border-radius-xl: 1rem;
	--bs-border-radius-xxl: 2rem;
	--bs-border-radius-2xl: var(--bs-border-radius-xxl);
	--bs-border-radius-pill: 50rem;
	--bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	--bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
	--bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
	--bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
	--bs-focus-ring-width: 0.25rem;
	--bs-focus-ring-opacity: 0.25;
	--bs-focus-ring-color: rgba(13, 110, 253, 0.25);
	--bs-form-valid-color: #198754;
	--bs-form-valid-border-color: #198754;
	--bs-form-invalid-color: #dc3545;
	--bs-form-invalid-border-color: #dc3545;
}



:root {
	--blue-50: #f5f9ff;
	--blue-100: #d0e1fd;
	--blue-200: #abc9fb;
	--blue-300: #85b2f9;
	--blue-400: #609af8;
	--blue-500: #3b82f6;
	--blue-600: #326fd1;
	--blue-700: #295bac;
	--blue-800: #204887;
	--blue-900: #183462;
	--green-50: #f4fcf7;
	--green-100: #caf1d8;
	--green-200: #a0e6ba;
	--green-300: #76db9b;
	--green-400: #4cd07d;
	--green-500: #22c55e;
	--green-600: #1da750;
	--green-700: #188a42;
	--green-800: #136c34;
	--green-900: #0e4f26;
	--yellow-50: #fefbf3;
	--yellow-100: #faedc4;
	--yellow-200: #f6de95;
	--yellow-300: #f2d066;
	--yellow-400: #eec137;
	--yellow-500: #eab308;
	--yellow-600: #c79807;
	--yellow-700: #a47d06;
	--yellow-800: #816204;
	--yellow-900: #5e4803;
	--cyan-50: #f3fbfd;
	--cyan-100: #c3edf5;
	--cyan-200: #94e0ed;
	--cyan-300: #65d2e4;
	--cyan-400: #35c4dc;
	--cyan-500: #06b6d4;
	--cyan-600: #059bb4;
	--cyan-700: #047f94;
	--cyan-800: #036475;
	--cyan-900: #024955;
	--pink-50: #fef6fa;
	--pink-100: #fad3e7;
	--pink-200: #f7b0d3;
	--pink-300: #f38ec0;
	--pink-400: #f06bac;
	--pink-500: #ec4899;
	--pink-600: #c93d82;
	--pink-700: #a5326b;
	--pink-800: #822854;
	--pink-900: #5e1d3d;
	--indigo-50: #f7f7fe;
	--indigo-100: #dadafc;
	--indigo-200: #bcbdf9;
	--indigo-300: #9ea0f6;
	--indigo-400: #8183f4;
	--indigo-500: #6366f1;
	--indigo-600: #5457cd;
	--indigo-700: #4547a9;
	--indigo-800: #363885;
	--indigo-900: #282960;
	--teal-50: #f3fbfb;
	--teal-100: #c7eeea;
	--teal-200: #9ae0d9;
	--teal-300: #6dd3c8;
	--teal-400: #41c5b7;
	--teal-500: #14b8a6;
	--teal-600: #119c8d;
	--teal-700: #0e8174;
	--teal-800: #0b655b;
	--teal-900: #084a42;
	--orange-50: #fff8f3;
	--orange-100: #feddc7;
	--orange-200: #fcc39b;
	--orange-300: #fba86f;
	--orange-400: #fa8e42;
	--orange-500: #f97316;
	--orange-600: #d46213;
	--orange-700: #ae510f;
	--orange-800: #893f0c;
	--orange-900: #642e09;
	--bluegray-50: #f7f8f9;
	--bluegray-100: #dadee3;
	--bluegray-200: #bcc3cd;
	--bluegray-300: #9fa9b7;
	--bluegray-400: #818ea1;
	--bluegray-500: #64748b;
	--bluegray-600: #556376;
	--bluegray-700: #465161;
	--bluegray-800: #37404c;
	--bluegray-900: #282e38;
	--purple-50: #fbf7ff;
	--purple-100: #ead6fd;
	--purple-200: #dab6fc;
	--purple-300: #c996fa;
	--purple-400: #b975f9;
	--purple-500: #a855f7;
	--purple-600: #8f48d2;
	--purple-700: #763cad;
	--purple-800: #5c2f88;
	--purple-900: #432263;
	--red-50: #fff5f5;
	--red-100: #ffd0ce;
	--red-200: #ffaca7;
	--red-300: #ff8780;
	--red-400: #ff6259;
	--red-500: #ff3d32;
	--red-600: #d9342b;
	--red-700: #b32b23;
	--red-800: #8c221c;
	--red-900: #661814;
	--primary-50: #f5f9ff;
	--primary-100: #d0e1fd;
	--primary-200: #abc9fb;
	--primary-300: #85b2f9;
	--primary-400: #609af8;
	--primary-500: #3b82f6;
	--primary-600: #326fd1;
	--primary-700: #295bac;
	--primary-800: #204887;
	--primary-900: #183462;
}






